<template>
    <div id="main">
        <div id="aztitle">
            <h2>dr n.med. Agnieszka Zwolińska</h2>
        </div>
        <div id="azcontent">
            <div id="azpic">
                <img src="../assetsPng/bioDrZwol.jpg">
            </div>
            <div id="aztext">
                <p>
                    Dr n. med. Agnieszka Zwolińska ukończyła studia medyczne na Warszawskim Uniwersytecie Medycznym w 2001 roku.<br>
                    W 2007 roku obroniła pracę doktorską z fizjologii człowieka, a w 2009 roku zakończyła specjalizację z chorób wewnętrznych w Klinice Chorób Wewnętrznych, Nadciśnienia Tętniczego i Angiologii w CSK ul Banacha 1a Pracując jednocześnie jako adiunkt w Katerze Fizjologii Doświadczalnej i Klinicznej Człowieka Warszawskiego Uniwersytetu Medycznego (2001-2011). <br>
                    Od 2008 roku rozwija swoją karierę w dziedzinie medycyny estetycznej, doskonaląc swoje umiejętności na szkoleniach w Wielkiej Brytanii, Francji, Monaco, Dubaju, Holandii, Włoszech, Szwecji. <br>
                    W 2013 roku została trenerem i szkoleniowcem dla Allergan Medical Institute, będąc odpowiedzialna za szkolenia w całej Polsce. Dodatkowo, odbyła roczne indywidualne szkolenie u światowej sławy chirurga plastycznego z Brazylii, Mauricio de Maio, co pozwoliło jej zdobyć tytuł międzynarodowego trenera i szkoleniowca dla AMI. Od kilku lat prowadzi szkolenia także za granicą w Czechach, Chorwacji, Słowenii, Serbii i Węgrzech. <br>
                    Jest także konsultantem zajmującym się powikłaniami w dziecinie medycyny estetycznej w Polsce i krajach nadbałkańskich. A także członkiem międzynarodowego programu Leadership&Expertise Advancement Program, którego celem jest ochrona właściwych kierunków i trendów medycyny estetycznej. Od kilku lat jest także członkiem Stowarzyszenia Lekarzy Dermatologów Estetycznych. <br>
                    Pionier zabiegów miomodulacyjnych w Polsce, których celem jest pomoc pacjentom z porażeniem nerwu twarzowego, asymetrią twarzy z powodów neurologicznych, onkologicznych jak i pacjentom po rozszczepie wargi. <br>
                    Zabiegami miomodulacji zajmuje się od ponad 5 lat, prezentując swoje osiągnięcia na kongresach międzynarodowych, publikując swoje doświadczenia w polskich pismach neurologicznych i międzynarodowych.
                </p>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style scoped>
#main{
    min-height: 90vh;
    display: flex;
    flex-direction: column;
}
#aztitle{
    margin-top: 30px;
    text-align: center;
    margin-bottom: 30px;
}
#azcontent{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
}
#azpic{
    margin-left: 5%;
    max-width: 400px;
}
#aztext{
    display: flex;
    text-align: justify;
    max-width: 500px;
    margin-right: 5%;
}
@media(max-width:1000px){
    #main{
        display: flex;
        flex-direction: column;
    }
    #azcontent{
        display: flex;
        flex-direction: column;
    }
    #aztext{
        margin-top: 5%;
        max-width: 80%;
        margin-right: 0%;
    }
    #azpic{
        margin-left: 0%;
    }
}
</style>
