<template>
    <div id="main">
        <div id="estethic">
            <div id="estethic-button">
                <h2 @click="toggleCollapse1">MEDYCYNA ESTETYCZNA</h2>
            </div>
            <div class="collapse-container" id="estethic-content">
                <div class="collapse-left">
                    <BCollapse :visible="collapse1Visible" id="collapse-1">
                        <BCard bg-variant="transparent" class="no-border mt-4">
                            <div class="category">
                                <h3>Cellular Matrix</h3>
                                <div class="service">
                                    <span>Twarz & Szyja</span><span>PLN 2300</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Dekolt</span><span>PLN 2300</span>
                                </div>
                                <div class="service">
                                    <span>Szyja & Dekolt</span><span>PLN 2300</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Dekolt & Szyja</span><span>PLN 3500</span>
                                </div>
                                <div class="service">
                                    <span>Twarz</span><span>PLN 1300</span>
                                </div>
                                <div class="service">
                                    <span>Szyja</span><span>PLN 1300</span>
                                </div>
                                <div class="service">
                                    <span>Dekolt</span><span>PLN 1300</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Juvederm Volite</h3>
                                <div class="service">
                                    <span>1 ampułka</span><span>PLN 1600</span>
                                </div>
                                <div class="service">
                                    <span>2 ampułki</span><span>PLN 2600</span>
                                </div>
                                <div class="service">
                                    <span>3 ampułki</span><span>PLN 3700</span>
                                </div>
                                <div class="service">
                                    <span>Dłonie</span><span>PLN 2600</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Mezoterapia</h3>
                                <div class="service">
                                    <span>Okolica oczu</span><span>PLN 400</span>
                                </div>
                                <div class="service">
                                    <span>Dekolt</span><span>PLN 700-800</span>
                                </div>
                                <div class="service">
                                    <span>Szyja</span><span>PLN 700-800</span>
                                </div>
                                <div class="service">
                                    <span>Twarz</span><span>PLN 700-800</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja</span><span>PLN 1300-1500</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja & Dekolt</span><span>PLN 1800-1900</span>
                                </div>
                                <div class="service">
                                    <span>Antycellulitowa</span><span>PLN 500/amp</span>
                                </div>
                                <div class="service">
                                    <span>Przebarwienia</span><span>PLN 400/amp</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Mezoterapia skóry głowy</h3>
                                <div class="service">
                                    <span>Toskanii</span><span>PLN 2100</span>
                                </div>
                                <div class="service">
                                    <span>Dermaheal HL</span><span>PLN 400</span>
                                </div>
                            </div>


                            <div class="category">
                                <h3>Osocze Regeneris</h3>
                                <div class="service">
                                    <span>Twarz</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Szyja</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Dekolt</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Skóra głowy</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja</span><span>PLN 1700</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja & Dekolt</span><span>PLN 2400</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Redermalizacja</h3>
                                <div class="service">
                                    <span>Dekolt</span><span>PLN 800-900</span>
                                </div>
                                <div class="service">
                                    <span>Szyja</span><span>PLN 800-900</span>
                                </div>
                                <div class="service">
                                    <span>Twarz</span><span>PLN 800-900</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Toksyna botulinowa</h3>
                                <div class="service">
                                    <span>Kurze łapki</span><span>PLN 700</span>
                                </div>
                                <div class="service">
                                    <span>Lwia zmarszczka</span><span>PLN 700</span>
                                </div>
                                <div class="service">
                                    <span>Poprzeczne zmarszczki czoła</span><span>PLN 700</span>
                                </div>
                                <div class="service">
                                    <span>Full face</span><span>PLN 2000</span>
                                </div>
                                <div class="service">
                                    <span>Całe czoło</span><span>PLN 1300</span>
                                </div>
                                <div class="service">
                                    <span>Nadpotliwość</span><span>PLN 2000</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Wolumetria</h3>
                                <div class="service">
                                    <span>7-9 punktowy lifting twarzy</span><span>PLN 6500</span>
                                </div>
                                <div class="service">
                                    <span>1 ampułka</span><span>PLN 1600</span>
                                </div>
                                <div class="service">
                                    <span>Kolejna ampułka</span><span>PLN 1200</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Bioremodeling Prophilo</h3>
                                <div class="service">
                                    <span>1 ampułka</span><span>PLN 1500</span>
                                </div>
                                <div class="service">
                                    <span>2 ampułki</span><span>PLN 2500</span>
                                </div>
                                <div class="service">
                                    <span>Prophilo Body</span><span>PLN 2300</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Lipoliza</h3>
                                <div class="service">
                                    <span>Bryczesy</span><span>PLN 800</span>
                                </div>
                                <div class="service">
                                    <span>Brzuch</span><span>PLN 800</span>
                                </div>
                                <div class="service">
                                    <span>Kolana</span><span>PLN 800</span>
                                </div>
                                <div class="service">
                                    <span>Podbródek</span><span>PLN 200</span>
                                </div>
                            </div>


                            <div class="category">
                                <h3>Pozostałe usługi</h3>
                                <div class="service">
                                    <span>HArmoniCA - biostymulator</span><span>PLN 3500</span>
                                </div>
                                <div class="service">
                                    <span>Dolina łez</span><span>PLN 1500</span>
                                </div>
                                <div class="service">
                                    <span>Modelowanie ust</span><span>PLN od 1200</span>
                                </div>
                                <div class="service">
                                    <span>Nawilżenie ust</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Nucleofill Eyes</span><span>PLN 2100</span>
                                </div>
                                <div class="service">
                                    <span>Jalupro Young Eye</span><span>PLN 1200</span>
                                </div>
                                <div class="service">
                                    <span>Miomodulacja</span><span>do ustalenia</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Konsultacje</h3>
                                <div class="service">
                                    <span>Medycyna estetyczna</span><span>PLN 300</span>
                                </div>
                                <div class="service">
                                    <span>Hialuronidaza-leczenie powikłań powstałych w innych salonach</span><span>PLN od 2500</span>
                                </div>
                            </div>
                        </BCard>
                    </BCollapse>
                </div>
            </div>
        </div>
        <div id="cosmetology">
            <div id="cosmetology-button">
                <h2 @click="toggleCollapse2">KOSMETOLOGIA</h2>
            </div>
            <div class="collapse-container" id="cosmetology-content">   
                <div class="collapse-mid">
                    <BCollapse :visible="collapse2Visible" id="collapse-2">
                        <BCard bg-variant="transparent" class="no-border mt-4">
                            <div class="category">
                                <h3>Retinal Infusion Peel Osmosis</h3>
                                <div class="service">
                                    <span>Exosomes Barrier Infusion Osmosis</span><span>PLN 550</span>
                                </div>
                                <div class="service">
                                    <span>Retinal Infusion Peel Osmosis</span><span>PLN 850</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Mezoterapia mikroigłowa</h3>
                                <div class="service">
                                    <span>Twarz</span><span>PLN 550</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja</span><span>PLN 750</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja & Dekolt</span><span>PLN 900</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Mezoterapia mikroigłowa z czynnikami wzrostu</h3>
                                <div class="service">
                                    <span>Twarz</span><span>PLN 1200</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja</span><span>PLN 1500</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja & Dekolt</span><span>PLN 1800</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Peelingi chemiczne</h3>
                                <div class="service">
                                    <span>Twarz</span><span>PLN 350</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja</span><span>PLN 400</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja & Dekolt</span><span>PLN 450</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Laminacja</h3>
                                <div class="service">
                                    <span>Laminacja brwi z architekturą, farbką i regulacją</span><span>PLN 170</span>
                                </div>
                                <div class="service">
                                    <span>Laminacja brwi</span><span>PLN 100</span>
                                </div>
                                <div class="service">
                                    <span>Regulacja brwi</span><span>PLN 30</span>
                                </div>
                                <div class="service">
                                    <span>Farbowanie i regulacja brwi</span><span>PLN 80</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>ZO Skin - Zabiegi Profesjonalne</h3>
                                <div class="service">
                                    <span>ZO Skin Acne and Oil Control Treatment - Kuracja Przeciwtrądzikowa</span><span>PLN 400</span>
                                </div>
                                <div class="service">
                                    <span>ZO Skin Anti-Aging Treatment - Kuracja Przeciwstarzeniowa</span><span>PLN 400</span>
                                </div>
                                <div class="service">
                                    <span>ZO Skin Brightening Treatment - Kuracja Rozjaśniająca</span><span>PLN 400</span>
                                </div>
                                <div class="service">
                                    <span>ZO Skin Redness Treatment - Kuracja Na Zaczerwienienia</span><span>PLN 400</span>
                                </div>
                                <div class="service">
                                    <span>ZO Skin Stimulator Peel - Stymulacja Odnowy Komórkowej</span><span>PLN 400</span>
                                </div>
                                <div class="service">
                                    <span>ZO Skin Ultra Hydration Treatment - Ultra Nawilżenie</span><span>PLN 400</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Wosk</h3>
                                <div class="service">
                                    <span>Wąsik</span><span>PLN 30</span>
                                </div>
                                <div class="service">
                                    <span>Pachy</span><span>PLN 80</span>
                                </div>
                                <div class="service">
                                    <span>Ręce</span><span>PLN 85</span>
                                </div>
                                <div class="service">
                                    <span>Łydki/Uda</span><span>PLN 90</span>
                                </div>
                                <div class="service">
                                    <span>Całe nogi</span><span>PLN 160</span>
                                </div>
                                <div class="service">
                                    <span>Bikini klasyczne</span><span>PLN 90</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>EXION</h3>
                                <div class="service">
                                    <span>RF bez mikronakłuwania - Dekolt</span><span>PLN 700</span>
                                </div>
                                <div class="service">
                                    <span>RF bez mikronakłuwania - Okolica Oczu</span><span>PLN 400</span>
                                </div>
                                <div class="service">
                                    <span>RF bez mikronakłuwania - Szyja</span><span>PLN 700</span>
                                </div>
                                <div class="service">
                                    <span>RF bez mikronakłuwania - Twarz</span><span>PLN 700</span>
                                </div>
                                <div class="service">
                                    <span>RF bez mikronakłuwania - Twarz & Szyja</span><span>PLN 1200</span>
                                </div>
                                <div class="service">
                                    <span>RF bez mikronakłuwania - Twarz & Szyja & Dekolt</span><span>PLN 1700</span>
                                </div>
                                <div class="service">
                                    <span>RF mikroigłowa z nakłuwaniem - Blizny</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>RF mikroigłowa z nakłuwaniem - Dekolt</span><span>PLN 1500</span>
                                </div>
                                <div class="service">
                                    <span>RF mikroigłowa z nakłuwaniem - Okolica Oczu</span><span>PLN 1200</span>
                                </div>
                                <div class="service">
                                    <span>RF mikroigłowa z nakłuwaniem - Szyja</span><span>PLN 1500</span>
                                </div>
                                <div class="service">
                                    <span>RF mikroigłowa z nakłuwaniem - Twarz</span><span>PLN 1500</span>
                                </div>
                                <div class="service">
                                    <span>RF mikroigłowa z nakłuwaniem - Twarz & Szyja & Dekolt</span><span>PLN 2500</span>
                                </div>
                                <div class="service">
                                    <span>RF z ultradźwiękami bez mikronakłuwania - Ciało (jeden obszar)</span><span>PLN 700</span>
                                </div>
                                <div class="service">
                                    <span>RF z ultradźwiękami bez mikronakłuwania - Ciało (dwa obszary)</span><span>PLN 1300</span>
                                </div>
                                <div class="service">
                                    <span>Seria zabiegów RF z ultradźwiękami bez nakłuwania: 4 zabiegi na jedną okolicę</span><span>PLN 2500</span>
                                </div>
                                <div class="service">
                                    <span>Seria zabiegów RF z ultradźwiękami bez nakłuwania: 4 zabiegi na dwie okolice</span><span>PLN 4800</span>
                                </div>
                                <div class="service">
                                    <span>Seria zabiegów RF z ultradźwiękami bez nakłuwania: 4 zabiegi na trzy okolice</span><span>PLN 6200</span>
                                </div>
                                <div class="service">
                                    <span>Zabiegi łączone: RF z mikronakłuwaniem i ultradźwiękami (Twarz lub Szyja lub Dekolt)</span><span>PLN 1900</span>
                                </div>
                                <div class="service">
                                    <span>RF mikroigłowa z nakłuwaniem - rozstępy</span><span>PLN 1500</span>
                                </div>
                                <div class="service">
                                    <span>RF mikroigłowa z nakłuwaniem - Twarz & Szyja</span><span>PLN 1500</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Pozostałe</h3>
                                <div class="service">
                                    <span>Konsultacja Kosmetologiczna z wizualną analizą skóry z OBSERV520x</span><span>PLN 350</span>
                                </div>
                            </div>

                        </BCard>
                    </BCollapse>
                </div>
            </div>
        </div>
        <div id="laserotherapy">
            <div id="laserotherapy-button">
                <h2 @click="toggleCollapse3">LASEROTERAPIA</h2>
            </div>
            <div class="collapse-container" id="laserotherapy-content">
                <div class="collapse-right">
                    <BCollapse :visible="collapse3Visible" id="collapse-3">
                        <BCard bg-variant="transparent" class="no-border mt-4">
                            <div class="category">
                                <h3>FOTONA 4D</h3>
                                <div class="service">
                                    <span>FOTONA 4D Laserowy lifting twarzy</span><span>PLN 3500</span>
                                </div>
                                <div class="service">
                                    <span>FOTONA 4D Odmładzanie twarzy dla mężczyzn</span><span>PLN 3500</span>
                                </div>
                                <div class="service">
                                    <span>FOTONA LipLase modelowanie ust</span><span>PLN 800</span>
                                </div>
                                <div class="service">
                                    <span>FOTONA Smooth Eye stymulacja skóry wokół oczu</span><span>PLN 800</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Peeling Laserowy Głęboki</h3>
                                <div class="service">
                                    <span>Deep Peel - Dekolt</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Deep Peel - Szyja</span><span>PLN 800</span>
                                </div>
                                <div class="service">
                                    <span>Deep Peel - Twarz</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Deep Peel - Twarz & Szyja</span><span>PLN 1500</span>
                                </div>
                                <div class="service">
                                    <span>Deep Peel - Twarz & Szyja & Dekolt</span><span>PLN 2100</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Peeling Laserowy Średni</h3>
                                <div class="service">
                                    <span>Medium Peel - Dekolt</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Medium Peel - Szyja</span><span>PLN 800</span>
                                </div>
                                <div class="service">
                                    <span>Medium Peel - Twarz</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Medium Peel - Twarz & Szyja</span><span>PLN 1500</span>
                                </div>
                                <div class="service">
                                    <span>Medium Peel - Twarz & Szyja & Dekolt</span><span>PLN 2100</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Peeling Laserowy Lekki</h3>
                                <div class="service">
                                    <span>Light Peel - Dekolt</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Light Peel - Szyja</span><span>PLN 800</span>
                                </div>
                                <div class="service">
                                    <span>Light Peel - Twarz</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Light Peel - Twarz & Szyja</span><span>PLN 1500</span>
                                </div>
                                <div class="service">
                                    <span>Light Peel - Twarz & Szyja & Dekolt</span><span>PLN 2100</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Resurfacing Laserowy Frakcyjny</h3>
                                <div class="service">
                                    <span>Dekolt</span><span>PLN 1200</span>
                                </div>
                                <div class="service">
                                    <span>Szyja</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Twarz</span><span>PLN 1200</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja</span><span>PLN 1700</span>
                                </div>
                                <div class="service">
                                    <span>Twarz & Szyja & Dekolt</span><span>PLN 2300</span>
                                </div>
                            </div>

                            <div class="category">
                                <h3>Pozostałe</h3>
                                <div class="service">
                                    <span>Laserowe Leczenie Trądziku</span><span>PLN 700</span>
                                </div>
                                <div class="service">
                                    <span>Redukcja Blizn Potrądzikowych</span><span>PLN 2500</span>
                                </div>
                                <div class="service">
                                    <span>Redukcja Blizn Pozabiegowych/Powypadkowych</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Redukcja Przebarwień - Dłonie</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Redukcja Przebarwień - Twarz</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Redukcja Rozstępów</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Symulacja Wzrostu Włosów</span><span>PLN 1000</span>
                                </div>
                                <div class="service">
                                    <span>Usuwanie Plamek Żółtych</span><span>PLN 300</span>
                                </div>
                                <div class="service">
                                    <span>Usuwanie Włókniaków</span><span>PLN 200</span>
                                </div>
                                <div class="service">
                                    <span>Zamykanie Naczynek - Nos</span><span>PLN 400</span>
                                </div>
                                <div class="service">
                                    <span>Zamykanie Naczynek - Rumień</span><span>PLN 800</span>
                                </div>
                                <div class="service">
                                    <span>Zamykanie Naczynek - Twarz</span><span>PLN 900</span>
                                </div>
                            </div>
                        </BCard>
                    </BCollapse>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { BButton, BCollapse, BCard } from 'bootstrap-vue-next';
  
  export default {
    components: {
      BButton,
      BCollapse,
      BCard,
    },
    data() {
        return {
            collapse1Visible: false,
            collapse2Visible: false,
            collapse3Visible: false,
        };
    },
    methods: {
      toggleCollapse1() {
        this.collapse1Visible = !this.collapse1Visible;
      },
      toggleCollapse2() {
        this.collapse2Visible = !this.collapse2Visible;
      },
      toggleCollapse3() {
        this.collapse3Visible = !this.collapse3Visible;
      },
    },
  };
</script>

<style scoped>
#main {
    margin-top: 3%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
}
#main h2{
    color: #bd924a;
}
#estethic{
    display: flex;
    flex-direction: column;
}
#estethic-button{
    display: flex;
    justify-content: center;
}
#estethic-content{
    display: flex;
    justify-content: center;
}
#cosmetology{
    display: flex;
    flex-direction: column;
}
#cosmetology-button{
    display: flex;
    justify-content: center;
}
#cosmetology-content{
    display: flex;
    justify-content: center;
}
#laserotherapy{
    display: flex;
    flex-direction: column;
}
#laserotherapy-button{
    display: flex;
    justify-content: center;
}
#laserotherapy-content{
    display: flex;
    justify-content: center;
}

.collapse-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.category {
    justify-content: center;
    margin-bottom: 20px;
}

.service {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    padding: 8px 0;
    gap: 10px;
}

.service span {
    font-size: 16px;
}

h3 {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #bd924a;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
}
span{
    color: #bd924a;
}
.no-border {
    border: none !important;
    width: 800px;
}
@media(max-width: 768px){
    .no-border {
        border: none !important;
        width: 80%;
        margin: 0 auto;
    }
}

</style>
