<template>
    <div id="main">
        <div id="jztitle">
            <h2>dr n.med. Joanna Żyłkowska</h2>
        </div>
        <div id="jzcontent">
            <div id="jzpic">
                <img src="../assetsPng/bioDrZyl.jpg">
            </div>
            <div id="jztext">
                <p>
                    Dr n.med. Joanna Żyłkowska w 2004 roku ukończyła studia medyczne na Warszawskim Uniwersytecie Medycznym <br>
                    W latach 2005 -2009 pracowała jako wykładowca w Katedrze Fizjologii Doświadczalnej i Klinicznej Człowieka Warszawskiego Uniwersytetu Medycznego. <br>
                    W 2012 roku zdała egzamin specjalizacyjny z chorób wewnętrznych po przebytym szkoleniu specjalizacyjnym w Klinice Chorób Wewnętrznych Klatki Piersiowej Instytutu Gruźlicy i Chorób Płuc w Warszawie oraz Europejskim Centrum Zdrowia w Otwocku. <br>
                    W 2013 roku obroniła z wyróżnieniem doktorat pt. „Ocena wpływu stopnia poszerzenia tętnicy płucnej na rokowanie w tętniczym i zakrzepowo-zatorowym nadciśnieniu płucnym” <br>
                    Od kilkunastu lat zajmuje się medycyną estetyczną. Uczestniczyła w licznych szkoleniach zagranicznych z zakresu anatomii twarzy, medycyny regeneracyjnej oraz technik iniekcji kwasu hialuronowego i toksyny botulinowej m.in. w Londynie, Paryżu, Malmo, Dubaju, Wenecji. <br>
                    Od kilku lat jest trenerem i wykładowcą w ramach Allergan Medical Institute regularnie prowadząc szkolenia dla lekarzy w całej Polsce. <br>
                    Członkini Stowarzyszenia Lekarzy Dermatologów Estetycznych. <br>
                    Zajmuje się również zabiegami odtwórczymi u pacjentów po operacjach z powodu rozszczepu wargi i podniebienia oraz zabiegami miomodulacyjnymi u pacjentów z porażeniem nerwu twarzowego. <br>
                    Prywatnie mama trójki dzieci, kochająca aktywny tryb życia i grę na fortepianie.
                </p>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style scoped>
#main{
    min-height: 90vh;
    display: flex;
    flex-direction: column;
}
#jztitle{
    margin-top: 30px;
    text-align: center;
    margin-bottom: 30px;
}

#jzcontent{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
}
#jzpic{
    margin-left: 5%;
    max-width: 500px;
}
#jztext{
    margin-right: 5%;
    display: flex;
    text-align: justify;
    max-width: 500px;
}
@media(max-width:1000px){
    #main{
        display: flex;
        flex-direction: column;
    }
    #jzcontent{
        display: flex;
        flex-direction: column;
    }
    #jztext{
        margin-top: 5%;
        max-width: 80%;
        margin-right: 0%;
    }
    #jzpic{
        margin-left: 0%;
    }
}
</style>
