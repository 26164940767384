import { createRouter, createWebHistory } from 'vue-router';
import Main  from '@/components/Main.vue';
import Pricelist from "@/components/Pricelist.vue";
import Contact from "@/components/Contact.vue"
import Team from "@/components/Team.vue"
import Gallery from "@/components/Gallery.vue"
// import News from "@/components/News.vue"
import Estethic from "@/components/Estethic.vue"
import Cosmetology from "@/components/Cosmetology.vue"
import Laserotherapy from "@/components/Laserotherapy.vue"
import Dr_Zwolinska from "@/components/Dr_Zwolinska.vue"
import Dr_Zylkowska from "@/components/Dr_Zylkowska.vue"


const routes = [
    {
        path: '/',
        name: '',
        component: Main,
    },
    {
        path: '/cennik',
        name: 'cennik',
        component: Pricelist,
    },
    {
        path: '/kontakt',
        name: 'kontakt',
        component: Contact,
    },
    {
        path: '/zespol',
        name: 'zespol',
        component: Team,
    },
    {
        path: '/galeria',
        name: 'galeria',
        component: Gallery,
    },
    // {
    //     path: '/aktualnosci',
    //     name: 'aktualnosci',
    //     component: News,
    // },
    {
        path: '/medycyna-estetyczna',
        name: 'medycyna-estetyczna',
        component: Estethic,
    },
    {
        path: '/kosmetologia',
        name: 'kosmetologia',
        component: Cosmetology,
    },
    {
        path: '/laseroterapia',
        name: 'laseroterapia',
        component: Laserotherapy,
    },
    {
        path: '/dr-zwolinska',
        name: 'dr-zwolinska',
        component: Dr_Zwolinska,
    },{
        path: '/dr-zylkowska',
        name: 'dr-zylkowska',
        component: Dr_Zylkowska,
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition; // powrót do zapisanej pozycji (np. przy cofaniu w przeglądarce)
      } else {
        return { top: 0 }; // przewijanie do góry
      }
    },
  });

export default router;