<template>
    <div id="main">
      <img src="../assetsPng/KlinikJa-93.jpg" />
      <img src="../assetsPng/KlinikaJa-1.jpg" />
      <img src="../assetsPng/KlinikaJa-5.jpg" />
      <img src="../assetsPng/KlinikJa-8.jpg" />
      <img src="../assetsPng/KlinikaJa-10.jpg" />
      <img src="../assetsPng/KlinikaJa-15.jpg" />
      <img src="../assetsPng/KlinikaJa-17.jpg" />
      <img src="../assetsPng/KlinikaJa-28.jpg" />
      <img src="../assetsPng/KlinikaJa-32.jpg" />
      <img src="../assetsPng/KlinikaJa-51.jpg" />
      <img src="../assetsPng/KlinikaJa-55.jpg" />
      <img src="../assetsPng/KlinikaJa-63.jpg" />
      <img src="../assetsPng/KlinikaJa-69.jpg" />
      <img src="../assetsPng/KlinikJa-74.jpg" />
    </div>
  </template>
  
  <script>
  export default {
    name: "ImageGallery",
  };
  </script>
  
  <style scoped>
  #main {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Pozwala na zawijanie obrazków */
    gap: 10px; /* Odstępy między obrazkami */
    justify-content: center; /* Wyśrodkowanie obrazków */
  }
  
  img {
    width: 500px; /* Domyślna szerokość obrazków */
    height: auto; /* Zachowanie proporcji obrazków */
  }
  
  @media (max-width: 768px) {
    img {
      width: 350px; /* Szerokość obrazków na urządzeniach mobilnych */
    }
  }
  </style>
  