<template>
    <footer id="footer">
        <div id="links-left">
            <a href="https://rejestracja.medfile.pl/register/index/?uuid=3aa71cad-f0cf-1e7f-13fd-3b4f5e9eeaa1">ZAPISY ONLINE</a>
            <router-link to="/cennik">CENNIK </router-link>
        </div>
        <div id="links-mid">
            <a>KLINIKA JA</a>
            <a>JAKTOROWSKA 8</a>
            <a>01-202 WARSZAWA</a>
        </div>
        <div id="links-right">
            <a href="https://www.facebook.com/profile.php?id=61555627505963" >
                <img src="@/assetsPng/fbii.png">
            </a>
            <a href="https://www.instagram.com/klinika_ja/" >
            <img src="@/assetsPng/igi.png">
            </a>
        </div>
    </footer>
</template>

<script>
</script>

<style>

#footer{
    display: flex;
    background-color: #1f1c1c;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
#links-left{
    margin-left: 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #bd924a;
    text-align: center;
}
#links-left a,
#links-mid a,
#links-left router-link,
#links-mid router-link {
    text-decoration: none;
    color: #bd924a;
}
#links-mid{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #bd924a;
    text-align: center;
}
#links-right{
    display: flex;
    justify-content: space-around;
    margin-right: 3%;
}
</style>
