<template>
    <div id="main">
        <div id="sec1">
            <div id="text1">
                <h2>Harmonia Ciała i Ducha</h2>
                <p>
                    W naszym sercu leży przekonanie, że prawdziwe piękno rodzi się z harmonii między ciałem a duchem. Nasza klinika medycyny estetycznej aspiruje do bycia miejscem, gdzie każdy aspekt opieki nad pacjentem jest przesiąknięty głębokim zrozumieniem i szacunkiem dla unikalnej indywidualności każdej osoby. Wierzymy, że kluczem do osiągnięcia pełnego potencjału naszego „własnego JA” jest holistyczne podejście, które łączy najnowsze osiągnięcia medycyny estetycznej z tradycyjnymi metodami poprawy dobrostanu.
                </p>
            </div>
            <div id="pic1">
                <img src="../assetsPng/zdj1.png">
            </div>
        </div>
        <div id="sec1">
            <div id="pic1">
                <img src="../assetsPng/zdj2.png">
            </div>
            <div id="text1">
                <h2>Filozofia "Własnego JA"</h2>
                <p>
                    Nasza filozofia opiera się na przekonaniu, że każdy z nas posiada wewnętrzne piękno, które zasługuje na to, by zostać odkrytym i podkreślonym. Dążymy do tego, aby nasze zabiegi nie tylko poprawiały wygląd zewnętrzny, ale także wspierały wewnętrzne poczucie wartości, pewności siebie i harmonii. Uważamy, że dbanie o siebie nie powinno być postrzegane jako próba zmiany swojego „ja” na coś lepszego, lecz jako wyraz szacunku i miłości do siebie, które będzie wspierać „moje lepsze ja”:)
                </p>
            </div>
        </div>
        <div id="sec1">
            <div id="text1">
                <h2>
                    Holistyczne Podejście do Piękna
                </h2>
                <p>
                    Rozumiemy, że piękno i zdrowie to nie tylko kwestie estetyczne, ale także odzwierciedlenie ogólnego stanu zdrowia, równowagi emocjonalnej i dobrostanu psychicznego. Dlatego nasza klinika oferuje szeroki zakres usług, od zaawansowanych zabiegów medycyny estetycznej, przez doradztwo w zakresie żywienia, aż po konsultacje wspierające zdrowie psychiczne. Naszym celem jest zapewnienie kompleksowej opieki, która przynosi korzyści na wszystkich poziomach – fizycznym, emocjonalnym i duchowym.
                </p>
            </div>
            <div id="pic1">
                <img src="../assetsPng/zdj3.png">
            </div>
        </div>
        <div id="sec1">
            <div id="pic1">
                <img src="../assetsPng/zdj4.png">
            </div>
            <div id="text1">
                <h2>
                    Innowacja i Indywidualizm
                </h2>
                <p>
                    Stawiamy na innowacyjność, ale jednocześnie głęboko wierzymy w indywidualne podejście do każdego pacjenta. Każda osoba, która przekracza próg naszej kliniki, jest traktowana z największą uwagą i szacunkiem dla jej unikalnych potrzeb i pragnień. Słuchamy naszych pacjentów, poświęcamy im czas i poszukujemy wspólnego planu działania w zależności od wskazań. Nasze zabiegi są dostosowane do indywidualnych celów i oczekiwań, a plan opieki jest zawsze współtworzony z pacjentem, aby jak najlepiej odpowiadał jego wizji siebie.
                </p>
            </div>
        </div>
        <div id="sec1">
            <div id="text1">
                <h2>
                    Zobowiązanie do Doskonałości
                </h2>
                <p>
                    Nasze zobowiązanie do doskonałości objawia się nie tylko w wysokiej jakości świadczonych usług, ale także w ciągłym dążeniu do rozwoju i edukacji. Nasz zespół to pasjonaci, którzy nieustannie poszukują nowych możliwości rozwoju i doskonalenia swoich umiejętności, aby móc oferować naszym pacjentom to, co w medycynie estetycznej najlepsze i najbardziej innowacyjne.
                </p>
            </div>
            <div id="text1">
                <h2>
                    Zaproszenie do Podróży
                </h2>
                <p>
                    Zapraszamy każdego, kto pragnie pielęgnować swoje „własne JA” w atmosferze szacunku, zrozumienia i profesjonalizmu do dołączenia do naszej społeczności. W naszej klinice każda podróż ku lepszemu samopoczuciu i piękniejszemu wyglądowi jest świętowana jako krok w stronę głębszego poznania i akceptacji siebie. Razem możemy odkryć pełnię Twojego potencjału i pomóc Ci świecić prawdziwym, nienaruszonym pięknem, które płynie z harmonii między ciałem a duchem.
                </p>
            </div>
        </div>
        <div id="separator">
            <img src="../assetsPng/przerywnik2.png">
        </div>
        <div id="sec2">
            <div id="title2">
                <h2>
                    Witamy w Świecie Doskonałości i Profesjonalizmu
                </h2>
            </div>
            <div id="text2">
                <p>
                    Z ogromną przyjemnością zapraszamy Państwa do świata, gdzie piękno spotyka się z nauką, a marzenia o doskonałym wyglądzie stają się rzeczywistością. Nasza klinika medycyny estetycznej to miejsce, w którym profesjonalizm, zaawansowane technologie i indywidualne podejście do każdego pacjenta tworzą wyjątkową przestrzeń dedykowaną Twojemu pięknu i dobrostanowi.
                </p>
            </div>
        </div>
        <div id="separator">
            <img src="../assetsPng/przerywnik2.png">
        </div>
        <div id="sec3">
            <div id="pic3">
                <img src="../assetsPng/zdj5.jpg">
            </div>
            <div id="text3">
                <h2>
                    Dlaczego warto nas wybrać?
                </h2>
                <p>
                    • <strong>Wybitni Specjaliści i Trenerzy</strong>: Nasz zespół tworzą doświadczeni lekarze specjaliści i trenerzy, którzy są uznawani za ekspertów w swojej dziedzinie. Dzięki ich wiedzy i umiejętnościom zapewniamy usługi na najwyższym światowym poziomie.<br>
                    • <strong>Innowacyjne Zabiegi</strong>: Stale poszukujemy i wdrażamy najnowsze osiągnięcia medycyny estetycznej, aby nasi pacjenci mogli korzystać z najbardziej zaawansowanych i skutecznych metod odmładzania i profilaktyki.<br>
                    • <Strong>Indywidualne Podejście</Strong>: Rozumiemy, że każdy pacjent jest wyjątkowy. Dlatego oferujemy spersonalizowane plany zabiegów, dopasowane do indywidualnych potrzeb, oczekiwań i stanu zdrowia, które powstają po wnikliwej konsultacji lekarskiej i kosmetologicznej.<br>
                    • <strong>Komfort i Bezpieczeństwo</strong>: Zapewniamy, że wszystkie zabiegi przeprowadzane są w bezpiecznych warunkach, z użyciem najwyższej jakości produktów i sprzętu. Dbamy o to, by każda wizyta w naszej Klinice była dla Ciebie przyjemnym doświadczeniem.
                </p>
            </div>
        </div>
        <div id="separator">
            <img src="../assetsPng/przerywnik2.png">
        </div>
        <div id="sec2">
            <div id="title2">
                <h2>
                    Odkryj Swoje Nowe Ja
                </h2>
            </div>
            <div id="text2">
                <p>
                    Zachęcamy do zapoznania się z naszą ofertą zabiegów, które mogą odmienić nie tylko Twój wygląd, ale i samopoczucie. Od terapii odmładzających, przez modelowanie sylwetki, aż po zaawansowane procedury medyczne – nasza Klinika oferuje szeroki wachlarz usług, które pomogą Ci osiągnąć wymarzony efekt.
                </p>
            </div>
        </div>
        <div id="separator">
            <img src="../assetsPng/przerywnik2.png">
        </div>
        <div id="sec2">
            <div id="title2">
                <h2>
                    Skonsultuj się z Ekspertami
                </h2>
            </div>
            <div id="text2">
                <p>
                    Nie jesteś pewien, który zabieg będzie dla Ciebie najlepszy? Umów się na konsultację z naszymi specjalistami. Podczas spotkania omówimy Twoje oczekiwania, przeanalizujemy potrzeby Twojej skóry i zaproponujemy indywidualnie dobrany plan zabiegów.                </p>
            </div>
            <div id="pic2">
                <img src="../assetsPng/zdjdokt2.png">
            </div>
        </div>
        <div id="separator">
            <img src="../assetsPng/przerywnik2.png">
        </div>
        <div id="sec3">
            <div id="text3">
                <h2>
                    Dołącz do Grupy Zadowolonych Klientów
                </h2>
                <p>
                    Naszym największym sukcesem jest satysfakcja i zaufanie naszych pacjentów. Zapraszamy do zapoznania się z opiniami osób, które już skorzystały z naszych usług i przekonały się o ich wysokiej jakości.
                </p>
                <h2>
                    Zarezerwuj Wizytę
                </h2>
                <p>
                    Nie czekaj na przemianę. Skontaktuj się z nami już dziś, aby umówić swoją wizytę i rozpocząć podróż do lepszego samopoczucia i piękniejszego wyglądu. Czekamy na Ciebie, aby pomóc Ci odkryć Twoje nowe JA!
                </p>
            </div>
            <div id="pic3">
                <img src="../assetsPng/logozdj.png">
            </div>
        </div>
        <div id="separator">
            <img src="../assetsPng/przerywnik2.png">
        </div>
        <div id="sec4">

            <a href="https://rejestracja.medfile.pl/register/index/?uuid=3aa71cad-f0cf-1e7f-13fd-3b4f5e9eeaa1">
            <h2>Jesteś już zdecydowany/a? Zapisz się online na wizytę :)</h2>
            </a>
        </div>
        <div id="separator">
            <img src="../assetsPng/przerywnik2.png">
        </div>
        <div id="sec4">
            <router-link to="/medycyna-estetyczna"><h1>MEDYCYNA ESTETYCZNA</h1></router-link>
            <router-link to="/kosmetologia"><h1>KOSMETOLOGIA</h1></router-link>
            <router-link to="/laseroterapia"><h1>LASEROTERAPIA</h1></router-link>
        </div>
        <div id="separator">
            <img src="../assetsPng/przerywnik2.png">
        </div>
    </div>
</template>

<script>

</script>

<style>
#main{
    display: flex;
    flex-direction: column;
}
#sec1{
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
}
#text1{
    width: 300px;
    text-align: justify;
}
#text1 h2{
    text-align: center;
    color: #bd924a;
}
#separator{
    display: flex;
    align-items: center;
    justify-content: center;
}
#sec2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#title2{
    color: #bd924a;
    text-align: center;
    max-width: 450px;
}
#text2{
    text-align: justify;
    max-width: 500px;
}
#sec3{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
}
#text3{
    max-width: 400px;
}
#text3 p{
    text-align: justify;
}
#text3 h2{
    text-align: center;
    color: #bd924a;
}
#sec4{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #bd924a;
}
#sec4 a{
    color: #bd924a;
    text-decoration: none;
}
@media(max-width:1000px){
    #sec1{
        display: flex;
        flex-direction: column;
    }
    #text1{
        width: 80%;
        order: 1;
    }
    #pic1{
        order: 2;
    }
    #sec2{
        display: flex;
        flex-direction: column;
    }
    #text2{
        width: 80%;
    }
    #sec3{
        display: flex;
        flex-direction: column;
    }
    #text3{
        width: 80%;
    }
    #sec4{
        display: flex;
        flex-direction: column;
    }
    #sec4 h2{
        text-align: center;
    }

}
</style>