<template>
    <div id="main">
        <div id="entry">
            <h2>Rozpocznij Swoją podróż z Kliniką JA</h2>
            <p>
                Cieszymy się, że nasza oferta wzbudziła Twoje zainteresowanie. <br>
                Jesteśmy tutaj, aby odpowiedzieć na wszystkie Twoje pytania, rozwiać wątpliwości <br>
                oraz pomóc Ci zaplanować pierwsze kroki na Twojej indywidualnej ścieżce do piękna i zdrowia.
            </p>
        </div>
        <div id="contact">
            <p>
                <strong>Jak się z nami skontaktować:</strong> <br>
                <strong>Telefonicznie:</strong> Nasz zespół jest dostępny pod numerem <strong>453 630 680</strong> od poniedziałku do piątku w godzinach 09:00-19:00,
                a w soboty 10:00-14:00.<br>
                Chętnie umówimy Cię na konsultację lub odpowiemy na pytania dotyczące naszych usług.<br>
            </p>
            <p>
                <strong>E-mailowo:</strong> Preferujesz kontakt elektroniczny? Napisz do nas na adres: <strong>info@klinikaja.pl</strong> <br>
                Dołożymy starań, aby odpowiedzieć na Twoją wiadomość jak najszybciej.
            </p>
            <p>
                <strong>Facebook:</strong> klinika JA <br>
                <strong>Instagram:</strong> klinika_ja <br>
                <strong>Booksy:</strong> Klinika Ja <br>
                <strong>Odwiedź nas osobiście:</strong> Jeśli wolisz bezpośredni kontakt, zapraszamy do naszej kliniki <br>
                pod adresem <strong>ul. Jaktorowska 8, 01-202 Warszawa</strong>, wejście od ul. Karolkowej. <br>
                Będzie nam niezmiernie miło gościć Cię i przedstawić naszą ofertę.
            </p>
        </div>
        <div id="links">
            <a href="https://rejestracja.medfile.pl/register/index/?uuid=3aa71cad-f0cf-1e7f-13fd-3b4f5e9eeaa1"><strong>Jesteś już zdecydowany/a? Zapisz się online na wizytę :)</strong></a> <br><br>
            <a href="https://booksy.com/pl-pl/236269_klinika-ja-sp-zo-o_medycyna-estetyczna_3_warszawa#ba_s=seo"><strong>Zapisy również na booksy</strong></a> <br><br>
        </div>
        <div id="last">
            <p><strong>Jesteśmy dla Ciebie!</strong></p>
            <p>
                Pamiętaj, że bez względu na pytanie czy wątpliwość, jesteśmy tutaj, aby Ci pomóc. Naszym priorytetem jest Twoje zadowolenie i dobrostan, dlatego zależy nam na budowaniu otwartych i serdecznych relacji z Naszymi Klientami. <strong>Zapraszamy do kontaktu!</strong>
            </p>
        </div>
        <div class="google-map-container">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.5004254389532!2d20.975192376954112!3d52.23429355734276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecdc2510b97eb%3A0xba0e0a32b7fe284f!2sKlinika%20JA!5e0!3m2!1sen!2spl!4v1733616129040!5m2!1sen!2spl"
                width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    </div>
</template>

<script>
</script>

<style scoped>
#main{
    display: flex;
    min-height: 90vh;
    flex-direction: column;
    margin-top: 3%;
    margin-left: 10%;
    margin-right: 10%;
    max-width: 800px;
}
#links a{
    text-decoration: none;
    color: #bd924a;
}

.google-map-container {
    max-width: 400px;
    padding: 10px;
}

iframe {
    width: 100%;
    height: 400px;
}

@media (max-width: 768px) {
    iframe {
        height: 300px;
    }
}
</style>
